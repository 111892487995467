import React from "react";
import { Header } from '../Header';
import './MainLayout.styles.scss';
import { LayoutWithSidebar } from '../LayoutWithSidebar';

const MainLayout = ({ children, loading, tab, withSidebar }) => {
    return (<div className={'layout'}>
        <div className={"line"}>
            <Header tab={tab} />
        </div>
        {withSidebar ? (<LayoutWithSidebar tab={tab} className={'inner'}>
            {children}
        </LayoutWithSidebar>) :
            (<div className="inner">
                {children}
            </div>)
        }
    </div>)
}

export default React.memo(MainLayout);