import React from "react";
import './Taxpayers.styles.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SearchTable } from "../../../../components/SearchTable"
import moment from 'moment'


const Taxpayers = ({ items, onSearch}) => {
    const { t } = useTranslation();


    const getNameRow = (rowData, { rowIndex }) => {
        return <Link className={"nameCol"} to={`/taxpayers?tin=${rowData.taxNumber}`}>{rowData.name}</Link>
    };

    const getBoardingDateRow = (rowData, { rowIndex }) => {
        return <span>{moment(rowData.onBoardingDate, 'DD-MM-YYYY').format("DD/MM/YYYY")}</span>
    }

    

    return (
        <div className={"taxpayersContainer"}>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <SearchTable placeholder={t("taxpayers.searchPlaceholder")} onSearchHandler={onSearch}  />

            </div>
            <DataTable value={items} responsiveLayout="scroll" emptyMessage=" ">
                <Column field="name" header={t("taxpayers.name")} sortable body={getNameRow} />
                <Column field="taxNumber" header={t("taxpayers.tin")} sortable />
                <Column field="onBoardingDate" header={t("taxpayers.onBoardingDate")} sortable body={getBoardingDateRow} />
                <Column field="numberOfSubmittedInvoices" header={t("taxpayers.numOfSubmittedInvoices")} sortable />
                <Column field="taxPayerTotalTaxes" header={t("taxpayers.taxesAmounts")} sortable />
            </DataTable>
            
                </div >
        
    );
};

export default React.memo(Taxpayers);

