import React, { useState } from "react";
import './Addadmin.styles.scss';

const AddAdmin = ({ onAddAdmin ,handleCancel}) => {
  const [newAdmin, setNewAdmin] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdmin({
      ...newAdmin,
      [name]: value,
    });
  };

  const handleAddAdmin = () => {
    const validCharactersRegex = /^[a-zA-Z0-9$_]+$/;
if (!validCharactersRegex.test(newAdmin.username)) {
  alert("Username should only contain English letters (both uppercase and lowercase), numbers, '$', or '_'.");
  return; // Exit the function
}

  if (newAdmin.username.length < 4) {
    alert("Username must be at least 4 characters long.");
    return; // Exit the function
  }

    // Check if the password and confirmPassword match
    if (newAdmin.password !== newAdmin.confirmPassword) {
      alert("Password and Confirm Password do not match.");
      return; // Exit the function
    }
  
    // Check if the password length is at least 7 characters
    if (newAdmin.password.length < 7) {
      alert("Password must be at least 7 characters long.");
      return; // Exit the function
    }


    const englishLettersRegex = /[a-zA-Z]/g;
const password = newAdmin.password;

const englishLettersCount = (password.match(englishLettersRegex) || []).length;

if (englishLettersCount < 4) {
  alert("Password should contain at least 4 English letters (both uppercase and lowercase).");
  return; // Exit the function
}

    
    const englishLettersNumbersSymbolsRegex = /^[a-zA-Z0-9!@#$%^&*()_+[\]{}|:;"'<>,.?/~`-]+$/;
    if (!englishLettersNumbersSymbolsRegex.test(newAdmin.password)) {
      alert("Password should contain English letters (both uppercase and lowercase), numbers, and symbols.");
      return; // Exit the function
    }

    
  
    // If the validation passes, call the parent component's function to add the admin
    onAddAdmin(newAdmin);
  
    // Optionally, you can reset the input fields here if needed
    setNewAdmin({
      username: "",
      password: "",
      confirmPassword: "",
    });
  };

  return (
    <div className="add-admin-form">
      <h3>Add New Admin</h3>
      <input
        type="text"
        placeholder="Username"
        name="username"
        value={newAdmin.username}
        onChange={handleInputChange}
        className="new-input"
      />
      <input
        type="password"
        placeholder="Password"
        name="password"
        value={newAdmin.password}
        onChange={handleInputChange}
        className="new-input"
      />
      <input
        type="password"
        placeholder="Confirm Password"
        name="confirmPassword"
        value={newAdmin.confirmPassword}
        onChange={handleInputChange}
        className="new-input"
      />

      <button onClick={handleAddAdmin} className="new-button">
        Add
      </button>
      <button onClick={handleCancel} className="cancel-button">
        Cancel
      </button>
    </div>
  );
};

export default AddAdmin;
