import React, { useState } from 'react';
import './ChangePasswordForm.css';

function ChangePasswordForm({ onChangePassword, onCancel }) {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        
        if (newPassword === confirmPassword) {
            if (passwordRegex.test(newPassword)) {
                setPasswordsMatch(true);
                onChangePassword(currentPassword, newPassword);
            } else {
                setPasswordsMatch(false);
            }
        } else {
            setPasswordsMatch(false);
        }
    };
    

    return (
        
        <div className="form-container">
            <form onSubmit={handleFormSubmit}>
                <label htmlFor="currentPassword">Current Password:</label>
                <input
                    type="password"
                    id="currentPassword"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                />
                <label htmlFor="newPassword">New Password:</label>
                <input
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
                <label htmlFor="confirmPassword">Confirm New Password:</label>
                <input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                {!passwordsMatch && <p className="error-message">Passwords do not match</p>}
                <div className="button-container">
                    <button type="button" onClick={onCancel}>Cancel</button>
                    <button type="submit">Save</button>
                </div>
            </form>
        </div>
    );
}

export default ChangePasswordForm;
