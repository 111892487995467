import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { UserProvider } from './context/user';
import { SessionProvider } from './components/SessionProvider';
import { OverViewPage } from './pages/OverviewPage';
import { Taxpayer } from './pages/Taxpayer';
import { Login } from './pages/Login';
import { DashboardPage } from './pages/Dashboard'
import { AdminControl } from './pages/AdminControl';
import { ToastContainer } from 'react-toastify';
import { Permission} from './pages/Permission'
import { Logs} from './pages/Logs'

const App = () => {
  

  

  


  return (
    <BrowserRouter>
      <UserProvider>
        <SessionProvider>
          <Routes>
            <Route exact path={`/dashboard`} element={<DashboardPage tab= "dashboard"/>} />
            <Route  path={`/invoices`} element={<OverViewPage tab="invoices" />} />
            <Route path={`/taxpayers`} element={<Taxpayer tab="invoices" />} />
            <Route path='/admin_control' element={<AdminControl tab='admin_control'/>}/>
            <Route path='/permissions' element = {<Permission tab='permissions'/>}/>
            <Route path='/logs' element = {<Logs tab ='logs'/>}/>
          
            <Route
              path="/login"
              element={<Login />} replace />
              
          </Routes>
        </SessionProvider>
        <ToastContainer hideProgressBar newestOnTop={false} closeOnClick />
      </UserProvider>
    </BrowserRouter>
  );
}

export default React.memo(App);
