import React from "react";
import { ProgressBar } from "primereact/progressbar";
import "./loading.style.scss";

const Loading = (props) => {
  return (
    <>
      <div className="loader-overlay">
        <ProgressBar
          mode="indeterminate"
          color="#3F77AC"
          style={{ height: "5px" }}
        ></ProgressBar>{" "}
      </div>
    </>
  );
};

export default Loading;
