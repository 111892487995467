import React from "react";
import './TotalRegisteredDisplay.css'; // Import the CSS file for styling

const TotalRegisteredDisplay = ({ totalRegistered }) => {
  return (
    totalRegistered && (
      <div className="total-registered"> {/* Apply the CSS class to the div */}
        Total Tax Payers Registered This Month :  {totalRegistered}
      </div>
    )
  );
};

export default TotalRegisteredDisplay;
