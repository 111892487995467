import React from "react";
import LayoutWithSidebar from "./LayoutWithSidebar";
import { useNavigate } from "react-router-dom";


const LayoutWithSidebarContainer = ({ ...restProps }) => {

    const history = useNavigate()

    const handleTabClick = (value) => {
        history(`/${value}`)
    }


    return <LayoutWithSidebar {...restProps} onTabClick={handleTabClick}/>
}


export default React.memo(LayoutWithSidebarContainer);