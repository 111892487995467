import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import "./YearMonthFilter.styles.scss";
import moment from "moment";

const YearMonthFilter = ({ setSelectedDate, selectedDate }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    const formattedDate = moment(value).format("yyyy-MM-DD");
    setSelectedDate(formattedDate);
  };

  return (
    <div className={"calendarContainer"}>
      <Calendar
        name="selectedDate"
        value={selectedDate}
        onChange={handleChange}
        placeholder="Year and Month"
        dateFormat="yy-mm"
        className={"calendarInner mx-3"}
        showIcon
        view="month"
      />
    </div>
  );
};

export default React.memo(YearMonthFilter);
