import React, { useEffect, useRef, useState } from "react";
import Loading from "../../components/shared/Loading";
import AdminControl from "./AdminControl";
import { getDevicesList } from "../../api/methods";

const AdminControlContainer = ({ tab, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [deviceList, setDeviceList] = useState([]);

    const fetchDeviceList = (searchData) => {
        setLoading(true);

        // Define a search object with taxPayerName and TaxNumber properties
        const searchObject = {
            taxPayerName: searchData,
            TaxNumber: searchData
        };

        if (!searchObject.taxPayerName && !searchObject.TaxNumber) {
            setDeviceList([]);
            setLoading(false);
        } else {
            setDeviceList(null); // Reset the device list when a search is initiated

            getDevicesList({ page: 1, ...searchObject })
                .then((response) => {
                    setDeviceList(response);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handlePageChange = (pageValue) => {
        // Handle page change here
        // Implement the logic for changing the current page
    };

    useEffect(() => {
        // Initial data fetching or any other side effects can be placed here
    }, []); // Empty dependency array means it runs once on component mount

    return (
        <React.Fragment>
            {loading && <Loading />}
            {deviceList !== null && (
                <AdminControl
                    data={deviceList}
                    onChangePageHandler={handlePageChange}
                    onSearchHandler={fetchDeviceList}
                    tab={tab}
                    {...props}
                />
            )}
        </React.Fragment>
    );
};

export default React.memo(AdminControlContainer);
