import React, { useState } from "react";
import './SearchTable.style.scss';
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { DateFilter } from './components/DateFilter';
import { YearMonthFilter } from './components/YearMonthFilter';

const SearchTable = ({ placeholder, onSearchHandler, isInvoicesPage, isTaxPayerPage  , isLogs, flag}) => {
    const [searchValue, setSearchValue] = useState("");
    const [dateObject, setDateObject] = useState({
        issueDateFrom: "",
        issueDateTo: ""
    });
    const [dateFilter, setDateFilter ] = useState(Date.now());
    const [dateFilterKey, setDateFilterKey] = useState(Date.now());

    const { t } = useTranslation();

    const searchHandler = (e) => {
        if(isLogs)
        {
            const searchObject = {
                searchValue : searchValue,
                    dateObject
                };
                onSearchHandler(searchObject);
        }else{
            if (isTaxPayerPage) {
            
                onSearchHandler(searchValue , dateObject ,dateFilter);
                
            } else if (isInvoicesPage) {
                onSearchHandler(searchValue, dateObject);
            } else {
                onSearchHandler(searchValue);
                
            }
        }
        
        
    }
    
        
    
    
    const resetHandler = (e) => {
        setSearchValue("");
        onSearchHandler("");
        setDateObject({
            issueDateFrom: "",
            issueDateTo: ""
        })
        setDateFilterKey(Date.now());
        setDateFilter(Date.now());
    }

    // const showReset = () => {
    //     if(!isInvoicesPage) {
    //         return !!searchValue
    //     } else {
    //         return !!searchValue || !!dateObject.issueDateTo || !!dateObject.issueDateTo
    //     }
    // }
    const showReset = () => {
    return !!searchValue || (!!dateObject.issueDateFrom || !!dateObject.issueDateTo) || (flag && (isInvoicesPage || isLogs));
};

    
    

    return (
        <div className={"searchContainer"}>
            <p>{t("common.search")}</p>
            {isTaxPayerPage ? (
                // If isTaxPayerPage is true, render nothing (null)
                null
            ) : (
                // Otherwise, render the input text
                <span className="p-input-icon-right">
                    <i className="pi pi-search" />
                    <InputText value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={placeholder} />
                </span>
            )}
            {(isInvoicesPage || isLogs) &&  flag &&<span>
                <DateFilter key={dateFilterKey} setDateObject={setDateObject} />
            </span>}
            {isTaxPayerPage && !isLogs && <span>
                <YearMonthFilter selectedDate={new Date(dateFilter)} setSelectedDate={setDateFilter} />

            </span>}
            <button onClick={searchHandler} type="button" className={"searchBtn"}>{t("common.search")}</button>
            {showReset() && <button onClick={resetHandler} type="button" className={"resetBtn"}>{t("common.reset")}</button>}
        </div>
    );
};
export default React.memo(SearchTable);
