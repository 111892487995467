import React from "react";
import { MainLayout } from '../../components/MainLayout';
import './Taxpayer.styles.scss';
import { useTranslation } from "react-i18next";
import taxInvoices from '../../assets/img/taxInvoices.png';
import taxCash from '../../assets/img/taxCash.png';
import { SummaryCard } from "../../components/SummaryCard";
import { Invoices } from "./components/Invoices";



const TaxpayerPage = ({ data, onChangePageHandler, onSearchHandler, onDownloadReportHandler, tab }) => {
    const { t } = useTranslation();

    const overviews = [
        {
            title: t("overview.submittedInvoices"),
            value: data.totalInvoices,
            iconUrl: taxInvoices
        },
        {
            title: t("overview.collectedTaxes"),
            value: data.totalTaxes + " JOD ",
            iconUrl: taxCash
        },

    ];
    return (
        <MainLayout tab={tab} withSidebar={true}>
            <div className={"taxpayerContainer"}>
                <h2 className={"sectionTitle"}>{data.taxPayerName} <span className="mx-3">{data.taxNumber}</span></h2>

                <div className={"cards"}>
                    {overviews.map((ele, i) => <SummaryCard key={"SummaryCard_" + i} className={"cardItem"} title={ele.title} value={ele.value} icon={ele.iconUrl} />)}
                </div>

            </div>
            <div className={"invoices"}>
                <h2 className={"sectionTitle"}>{t("invoices.title")}</h2>

                <Invoices
                    items={data.taxpayerInvoiceDTOList}
                    onSearch={onSearchHandler}
                    activePage={data.currentPage - 1}
                    // numberOfPages={data.totalItems}
                    onDownloadReport={onDownloadReportHandler}
                    totalItems={data.totalItems}
                    pageSize={data.pageSize}
                    onChangePage={onChangePageHandler}
                />
            </div>
        </MainLayout>
    )
}

export default React.memo(TaxpayerPage);