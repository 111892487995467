import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from '../locales/en.json';
import arTranslation from '../locales/ar.json';

export const initTranslations = () => {
  const languageFromLocation = window.location.pathname.split('/')[1];

  const language =
    languageFromLocation === 'ar' || languageFromLocation === 'en'
      ? languageFromLocation
      : 'ar';

  // if (languageFromLocation !== 'ar' && languageFromLocation !== 'en') {
  //   window.location.replace(
  //     `/${language}${window.location.pathname}${window.location.search}`,
  //   );
  // }

  i18n.use(initReactI18next).init({
    lng: language,
    fallbackLng: language,
    resources: {
      en: {
        translation: enTranslation,
      },
      ar: {
        translation: arTranslation,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });
};

export default i18n;
