import React , {useState} from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { SearchTable } from "../../../../components/SearchTable";
import { OvalSpinner } from "../../../../components/shared/OvalSpinner";
import { ToggleButton } from 'primereact/togglebutton';
import './Admins.styles.scss';
import { Paginator } from 'primereact/paginator';

const Admins = ({admins, handleStatusChange, pageSize, totalItems, activePage, onChangePage, onSearchHandler, loading, onDeleteClick}) => {
  
  const { t } = useTranslation();
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const onPageChangeHandler = (p) => {
    onChangePage(p.page + 1);
  };

  return (
    <div className="AdminsContainer">
       
      <div className="d-flex justify-content-between align-items-center mb-4">
        <SearchTable placeholder={t("admins.searchPlaceholder")} onSearchHandler={onSearchHandler} />
      </div>

      <DataTable value={admins} responsiveLayout="scroll" rowGroupMode="rowspan">
        <Column field="id" header={t("admins.id")} sortable />
        <Column field="username" header={t("admins.username")} sortable />
        <Column header={t("admins.enableDisableAdmins")} sortable body={(rowData) => (
          loading[rowData?.status] ? (
            <OvalSpinner width={35} height={35} />
          ) : (
            <ToggleButton
              checked={rowData.status === true}
              onChange={(e) => handleStatusChange(e.value, rowData)}
              onIcon="pi pi-enabled" offIcon="pi pi-disabled"
              onLabel=""
              offLabel=""
            />
          )
        )} />

        <Column
          header={t("admins.delete")}
          body={(rowData) => (
            <button
              className="delete-button"
              onClick={() => {
                setSelectedRowData(rowData); // Store the selected rowData
                setConfirmationVisible(true); // Show confirmation dialog
              }}
            >
              Delete
            </button>
          )}
        />
      </DataTable>
      <Paginator first={activePage * pageSize} pageLinkSize={7} rows={pageSize} totalRecords={totalItems} onPageChange={onPageChangeHandler} />
       {/* Confirmation dialog with overlay */}
    {isConfirmationVisible && selectedRowData && (
      <div className="confirmation-overlay">
        <div className="confirmation-dialog">
          <p>Are you sure you want to delete this user?</p>
          <button onClick={() => {onDeleteClick(selectedRowData) ; setConfirmationVisible(false)}}>Yes</button>
          <button onClick={() => setConfirmationVisible(false)}>No</button>
        </div>
      </div>
    )}
    </div>
  );
};

export default React.memo(Admins);
