import React from "react";
import { OvalSpinner } from "../../../../components/shared/OvalSpinner";
import { ToggleButton } from 'primereact/togglebutton';

export const COLUMNS = ({loading, t, getRowName, onDeviceStatusChange}) => ([
    {
        field: 'name',
        header: t('admin_control.name'),
        sortable: true,
        body: getRowName
    },
    {
        field: 'taxNumber',
        header: t('admin_control.tin'),
        sortable: true,
        body: undefined
    },
    {
        field: 'activityNumber',
        header: t('admin_control.incomeSerialNumber'),
        sortable: false,
        body: undefined
    },
    {
        field: 'active',
        header: t('admin_control.enableDisableIntegration'),
        sortable: true,
        body: (rowData) => (loading[rowData?.activityNumber] ? <OvalSpinner width={35} height={35} /> : <ToggleButton
        checked={rowData.active}
        onChange={(e) => onDeviceStatusChange(e, rowData)}
        onIcon="pi pi-enabled" offIcon="pi pi-disabled"
        onLabel=""
        offLabel=""
    />)
    },
])