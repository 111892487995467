import React from "react";

import LoginPage from "./LoginPage";

const LoginContainer = () => {
    return (
        <LoginPage />
    )
}

export default React.memo(LoginContainer);