import axios from "axios";
import { API_URL} from '../constants/common';



export const loginUser = async (data) => {
    return axios.post(`${API_URL}/users/auth/dashboard/login`, data);
};

export const addNewAdmin = async (data) => {
    return axios.post(`${API_URL}/users/addDashboard/admin`, data);
};


export const getStatistics = async(dateFilter)=>
{
    return axios.get(`${API_URL}/dashboard/dashboard-statistics?Date=${dateFilter}`);
}



export const getTotalRegisteredTaxPayersByDate = async(data)=>
{
    return axios.get(`${API_URL}/dashboard/total-taxpayer-by-date?issueDateFrom=${data}`);
}



export const getTaxPayersList = async (filter) => {
    let url = `${API_URL}/dashboard/tax-payers?`;
    Object.keys(filter).forEach((k) => {
        url += `${k}=${filter[k]}&`;
    });

    return axios.get(url);
};

export const getLogs = async (filter) => {
let url = `${API_URL}/dashboard/getLogs?`;
Object.keys(filter).forEach((k) => {
    url += `${k}=${filter[k]}&`;
});
return axios.get(url)
}

export const getAllAdmins = async(searchData)=>
{
    let url = `${API_URL}/dashboard/getAllAdmins?`;
    Object.keys(searchData).forEach((k) => {
        url += `${k}=${searchData[k]}&`;
    });
    return axios.get(url);
}

export const getAdminsLogsReport = (filter) => {
    let url = `${API_URL}/dashboard/logs/export-excel?`;
    Object.keys(filter).forEach((k) => {
        url += `${k}=${filter[k]}&`;
    });
    return axios.get(url, { responseType: "blob", headers: { 'Content-Type': 'application/octet-stream' } });
};

export const getTaxpayersReport = (date) => {
    return axios.get(`${API_URL}/dashboard/tax-payers/export-excel?startDate=${date}`, { responseType: "blob", headers: { 'Content-Type': 'application/octet-stream' } });
};



export const getTaxpayerInvoicesReport = (taxpayerNumber , search) => {
    let url = `${API_URL}/dashboard/tax-payer/${taxpayerNumber}/export-excel?`;
    Object.keys(search).forEach((k) => {
        url += `${k}=${search[k]}&`;
    });
    return axios.get(url, { responseType: "blob", headers: { 'Content-Type': 'application/octet-stream' } });
};

export const getTaxPayerInvoicesList = (taxpayerNumber, filter) => {
    let url = `${API_URL}/dashboard/tax-payer/${taxpayerNumber}?`;
    Object.keys(filter).forEach((k) => {
        url += `${k}=${filter[k]}&`;
    });
    return axios.get(url);
};



export const changePassword = async (data) => {
    return axios.post(`${API_URL}/users/resetDash`, data);
};

export const getDevicesList = async (filter) => {
    let url = `${API_URL}/dashboard/tax-payers-devices?`;
    Object.keys(filter).forEach((k) => {
        url += `${k}=${filter[k]}&`;
    });

    const devicesList = await axios.get(url);


    const taxPayerListDto = []
    devicesList?.taxPayerListDto?.forEach(ele => {
        ele.taxPayerDevices?.forEach(device => {
            if (device.activityNumber) {
                taxPayerListDto.push({
                    ...device,
                    name: ele.name,
                    taxNumber: ele.taxNumber
                })
            }
        })
    })

    return ({
        ...devicesList,
        taxPayerListDto
    })
};


export const enableDisableDevice = async (activityNumber, dataObject) => {

    return axios.put(`${API_URL}/dashboard/${activityNumber}/status`, dataObject);
}

export const adminPermission = async (id, dataObject) => {

    return axios.put(`${API_URL}/dashboard/${id}/admin/status`, dataObject);
    
}

export const deleteAdmin = async (id) => {

    return axios.delete(`${API_URL}/dashboard/${id}/delete`);
    
}
