export const TOKEN_KEY = 'einv-token';
export const API_URL = process.env.REACT_APP_API_URL;
export const REFRESH_TOKEN = 'refresh-token';
export const SESSION_TIMEOUT = 'tokenExpiry';
export const USERNAME = 'username';
export const ROLE = 'role';
export const PERMISSION = 'permission';


export const TABS = [
    {
        label: 'tabs.dashboard',
        value: 'dashboard',
        privilege: ['ALL']
    },
    {
        label: 'tabs.invoices',
        value: 'invoices',
        privilege: ['ALL']
    },
    { 
        label: 'tabs.admin_control',
        value: 'admin_control',
        privilege:['SUPER_ADMIN','true']
        
    },
    {
        label:'tabs.permissions',
        value:'permissions',
        privilege:['SUPER_ADMIN']
    },
    {
        label:'tabs.logs',
        value:'logs',
        privilege:['SUPER_ADMIN']
    }
]

export const getTabsByPrivilege = () => {
    const userRole = localStorage.getItem('role');
    const userPermission = localStorage.getItem('permission');
    return TABS.filter(tab => tab.privilege.includes('ALL') || tab.privilege.includes(userRole) || tab.privilege.includes(userPermission));
}

// export const getTabsByPrivilege = () => {
//     const userRole = localStorage.getItem(ROLE); // Use the constant ROLE instead of 'role'
//     const userPermission = localStorage.getItem(PERMISSION); // Use the constant PERMISSION instead of 'permission';
//   alert(userPermission);
//     // Check if the user is a super admin or has permission equal to true
//     if (userRole === 'SUPER_ADMIN' || userPermission === 'true') {
//       return TABS;
//     } else {
//       // Filter tabs based on privileges
//       return TABS.filter((tab) =>
//         tab.privilege.includes('ALL') || tab.privilege.includes(userRole)
//       );
//     }
//   };

// export const INVOICE_TYPES_ENUM = {
//   CASH_INCOME: 'CASH_INCOME',
//   CASH_GENERAL_TAX: 'CASH_GENERAL_TAX',
//   CASH_SPECIAL_TAX: 'CASH_SPECIAL_TAX',
//   RECEIVABLE_INCOME: 'RECEIVABLE_INCOME',
//   RECEIVABLE_GENERAL_TAX: 'RECEIVABLE_GENERAL_TAX',
//   RECEIVABLE_SPECIAL_TAX: 'RECEIVABLE_SPECIAL_TAX',
// };
