import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { SearchTable } from "../../../../components/SearchTable";
import './Logform.styles.scss';
import { ExportReport } from '../../../../components/ExportReport';
import moment from 'moment';
import { Paginator } from 'primereact/paginator';

const Logform = ({ logs, pageSize, totalItems, activePage, onChangePage, onSearch, onDownloadReport }) => {
    const { t } = useTranslation();
    // const getBoardingDateRow = (rowData, { rowIndex }) => {
    //     return <span>{moment(rowData.dateOfChange, 'YYYY-MM-DD HH:mm').format("YYYY/MM/DD HH:mm")}</span>
    // }

    const getBoardingDateRow = (rowData, {rowIndex}) => {

        const date = moment(rowData.dateOfChange, 'YYYY-MM-DD HH:mm');
      
        date.add(3, 'hours'); // adjust for 3 hour offset
      
        return <span>{date.format("YYYY/MM/DD HH:mm")}</span>
      
      }
    const onPageChangeHandler = (p) => {
        onChangePage(p.page + 1);
    }

    // State variable to track which search table is open
    const [openSearchTable, setOpenSearchTable] = useState(null);

    // Function to toggle the visibility of a specific search table
    const toggleSearchTable = (searchTable) => {
        if (openSearchTable === searchTable) {
            // If the same search table button is clicked, close it
            setOpenSearchTable(null);
        } else {
            // Otherwise, open the clicked search table
            setOpenSearchTable(searchTable);
        }
    }

    return (
        <div className="logsContainer">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <button onClick={() => toggleSearchTable(1)}>Search By Admin</button>
                {openSearchTable === 1 && <SearchTable placeholder={t("logs.searchPlaceholder")} onSearchHandler={onSearch} isLogs={true} />}
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <button onClick={() => toggleSearchTable(2)}>Search By TIN</button>
                {openSearchTable === 2 && <SearchTable placeholder={t("logs.searchPlaceholdertin")} onSearchHandler={onSearch} isLogs={true} />}
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <button onClick={() => toggleSearchTable(3)}>Search By Date</button>
                {openSearchTable === 3 && (
                    <div>
                        <SearchTable
                            placeholder={t("logs.searchPlaceholdertin")}
                            isTaxPayerPage={true}
                            isLogs={true}
                            onSearchHandler={onSearch}
                            flag={true}
                        />
                    </div>
                )}
            </div>

            <ExportReport label={"Download as Excel"} onClickHandler={onDownloadReport} />

            <DataTable value={logs} responsiveLayout="scroll" emptyMessage=" ">
            <Column field="username" header={t("logs.username")} sortable/>
            <Column field="tin" header={t("logs.tin")} sortable/>
            <Column field="incomeSerialNumber" header={t("logs.incomeSerialNumber")} sortable/>
            <Column field="dateOfChange" header={t("logs.dateOfChange")} sortable body={getBoardingDateRow}/>
            <Column field="note" header={t("logs.note")}sortable/>
        </DataTable>
        <Paginator first={activePage * pageSize} pageLinkSize={7} rows={pageSize} totalRecords={totalItems} onPageChange={onPageChangeHandler} />

        </div>
    );
}

export default React.memo(Logform);
