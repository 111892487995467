// import React, { useState } from "react";
// import './Devices.styles.scss';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
// import { SearchTable } from "../../../../components/SearchTable";
// import * as api from '../../../../api/methods';
// import { COLUMNS } from "./Devices.config";
// import {ComfirmationModal} from "./ConfirmationModal/ConfirmationModal"
// import { USERNAME } from "../../../../constants/common";
// // import { showToast } from "../../../../helpers/showToast";

// const Devices = ({ items, totalItems, onSearch, activePage, pageSize, onChangePage, totalPages }) => {

//     const [renderItem, setRenderItems] = useState(items);
//     const [loading, setLoading] = useState({});

//     const { t } = useTranslation();

//     const getNameRow = (rowData) => {
//         return <Link className={"nameCol"} to={`/taxpayers?tin=${rowData.taxNumber}`}>{rowData.name}</Link>
//     };

//     const onPageChangeHandler = (p) => {
//         onChangePage(p.page + 1);
//     }

//     const onDeviceStatusChange = (e, rowData) => {
//         // if (e.value === true) {
//         //     alert("Action rejected");
//         //     return; // Don't call enableDisableDevice when the value is true
//         // }
    
//         const userConfirmed = window.confirm("Are you sure you want to disable this device?");
    
//         if (userConfirmed) {
//             let note;
//             do {
//                 note = prompt("Please enter a note:");
//             } while (!note); // Continue prompting until a non-empty note is provided
    
//             // Now 'note' is guaranteed to be non-null and non-empty
//             setLoading(prevState => ({
//                 ...prevState,
//                 [rowData.activityNumber]: true
//             }));
//             const dataObject = {
//                 enabled: e.value,
//                 username: localStorage.getItem(USERNAME),
//                 tin: rowData.taxNumber,
//                 note: note
//             };
//             api.enableDisableDevice(rowData.activityNumber, dataObject)
//                 .then(() => {
//                     const myItems = items.map(item => {
//                         if (item.activityNumber === rowData.activityNumber) {
//                             item.active = e.value;
//                         }
//                         return item;
//                     });
    
//                     setLoading(prevState => ({
//                         ...prevState,
//                         [rowData.activityNumber]: false
//                     }));
    
//                     setRenderItems(myItems);
//                 })
//                 .catch(() => {
//                     // Handle errors here
//                     setLoading(prevState => ({
//                         ...prevState,
//                         [rowData.activityNumber]: false
//                     }));
//                 });
//         }
//     }
    
    
    
    


//     return (
//         <div className={"devices_container"}>
//             <div className="d-flex justify-content-between align-items-center mb-4">
//                 <SearchTable placeholder={t("admin_control.searchPlaceholder")} onSearchHandler={onSearch} />
//             </div>
//             <DataTable value={renderItem} responsiveLayout="scroll" rowGroupMode="rowspan" groupRowsBy={["taxNumber", "name"]} emptyMessage=" " >
//                 {
//                     COLUMNS({ loading, t, getNameRow, onDeviceStatusChange }).map(column => (
//                         <Column
//                             field={column.field}
//                             header={column.header}
//                             sortable={column.sortable}
//                             body={column.body}
//                             key={column.field}
//                         />
//                     ))
//                 }
//             </DataTable>
//         </div >
//     );
// };

// export default React.memo(Devices);














import React, { useState } from "react";
import './Devices.styles.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SearchTable } from "../../../../components/SearchTable";
import * as api from '../../../../api/methods';
import { COLUMNS } from "./Devices.config";
import { USERNAME } from "../../../../constants/common";
import { Modal } from '../Modal';

const Devices = ({ items, onSearch }) => {
    const [renderItem, setRenderItems] = useState(items);
    const [loading, setLoading] = useState({});
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [note, setNote] = useState("");
    const [selectedDevice, setSelectedDevice] = useState(null);

    const { t } = useTranslation();

    const getNameRow = (rowData) => {
        return <Link className={"nameCol"} to={`/taxpayers?tin=${rowData.taxNumber}`}>{rowData.name}</Link>
    };


    const openNoteModal = (rowData) => {
        setSelectedDevice(rowData);
        setShowNoteModal(true);
    }

    const closeNoteModal = () => {
        setShowNoteModal(false);
        setSelectedDevice(null);
    }

    const onDeviceStatusChange = (e, rowData) => {
        if (e.value === true) {
            alert("Action rejected");
            return;
        }

        openNoteModal(rowData);
    }

    const handleSubmitNote = () => {
        if (note.trim() === "") {
            alert("Please enter a note.");
        } else {
            // Close the modal
            closeNoteModal();

            // Proceed with your API call
            setLoading(prevState => ({
                ...prevState,
                [selectedDevice.activityNumber]: true
            }));

            const dataObject = {
                enabled: !selectedDevice.active,
                username: localStorage.getItem(USERNAME),
                tin: selectedDevice.taxNumber,
                note: note
            };

            api.enableDisableDevice(selectedDevice.activityNumber, dataObject)
                .then(() => {
                    const updatedItems = renderItem.map(item => {
                        if (item.activityNumber === selectedDevice.activityNumber) {
                            item.active = !selectedDevice.active;
                        }
                        return item;
                    });

                    setLoading(prevState => ({
                        ...prevState,
                        [selectedDevice.activityNumber]: false
                    }));

                    setRenderItems(updatedItems);
                })
                .catch(() => {
                    // Handle errors here
                    setLoading(prevState => ({
                        ...prevState,
                        [selectedDevice.activityNumber]: false
                    }));
                });
        }
    };

    return (
        <div className={"devices_container"}>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <SearchTable placeholder={t("admin_control.searchPlaceholder")} onSearchHandler={onSearch} />
            </div>
            <DataTable value={renderItem} responsiveLayout="scroll" rowGroupMode="rowspan" groupRowsBy={["taxNumber", "name"]} emptyMessage=" " >
                {COLUMNS({ loading, t, getNameRow, onDeviceStatusChange }).map(column => (
                    <Column
                        field={column.field}
                        header={column.header}
                        sortable={column.sortable}
                        body={column.body}
                        key={column.field}
                    />
                ))}
            </DataTable>
            <Modal
                isOpen={showNoteModal}
                onClose={closeNoteModal}
                onConfirm={handleSubmitNote}
                title="Note"
                content={
                    <div>
                        <input className="modal-input"
                            type="text"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default React.memo(Devices);


