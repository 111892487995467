import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUserInfo } from '../../api/methods';
import { TOKEN_KEY, USERNAME } from '../../constants/common';

import { useUser } from '../../context/user';

import SessionProvider from './SessionProvider';

const SessionProviderContainer = (props) => {

  const history = useNavigate();
  const location = useLocation();

  const { user, dispatchUser } = useUser();

  const authToken = sessionStorage.getItem(TOKEN_KEY);

  useEffect(() => {
    if (!authToken) {
      if (location.pathname !== `/login`) {
        history(`/login`, { replace: true });
        return;
      }
    } else {
      if (!user) {
        const username = sessionStorage.getItem(USERNAME) || localStorage.getItem(USERNAME);
        dispatchUser({
          type: 'SET_USER',
          payload: { userName: username },
        });
        // getUserInfo().then((userInfo) => {
        //   dispatchUser({
        //     type: 'SET_USER',
        //     payload: { ...userInfo },
        //   });
        //   // history(`/dashboard`);
        // });
      }
      if (location.pathname === "/login") {
        history(`/dashboard`);
      }
    }
  }, [authToken, history, location.pathname, user]);

  return <SessionProvider {...props} />;
};

export default React.memo(SessionProviderContainer);
