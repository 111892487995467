import React , {useEffect, useState} from "react";
import Permission from './Permission';
import { getAllAdmins , adminPermission , deleteAdmin , addNewAdmin} from "../../api/methods";
import Loading from "../../components/shared/Loading";


const PermissionControlContainer = ({tab , ...Props}) => {
  const [loader, setLoader] = useState(false);
  const [response , setResponse] = useState([]);
  const [admins, setAdminsData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState({});

  const [search, setSearch] = useState({
    username : '',
    id : ''
});

const onAddAdmin = (adminData) => {

  const usernameWithPrefix = `Dashboard_${adminData.username}`;
  const adminDataWithPrefix = { ...adminData, username: usernameWithPrefix };
  setLoader(true);

  addNewAdmin(adminDataWithPrefix)
    .then((response) => {
      setAdminsData((prevAdmins) => [...prevAdmins, response]);
    })
    .catch((error) => {
      console.error("add new admin error:", error);
    })
    .finally(() => {
      setLoader(false);
    });
}


  const handelGetAllAdmins = () => {
    
    setLoader(true);
    getAllAdmins({page:activePage , ...search}).then((response) => {
        setLoader(false);
        setResponse(response);
        setAdminsData(response.listOfAdmins);
    }).catch((err) => {
        console.log(err);
        setLoader(false);
    })
};
useEffect(() => {
  handelGetAllAdmins(activePage);
}, [activePage , search ]);

const onChangePageHandler = (pageValue) => {
  setActivePage(pageValue);
}

  const handleStatusChange = (newValue, rowData) => {
    
    setLoading(prevState => ({
      ...prevState,
      [rowData.status]: true
  }));
  

    const dataObject = {
      status : newValue
    }
    adminPermission(rowData.id, dataObject)
                .then(() => {
                  const updatedadmins = admins.map(admin => {
                    if (admin.id === rowData.id) {
                      return { ...admin, status: newValue };
                    }
                    return admin;
                });

                    setLoading(prevState => ({
                      ...prevState,
                      [rowData.status]: false
                  }));
                  setAdminsData(updatedadmins);

                })
                .catch(() => {
                    
                  setLoading(prevState => ({
                    ...prevState,
                    [rowData.status]: false
                }));
                  
                });
  
                
  };

    

    const onSearchHandler = (searchValue) =>{
    
      if (searchValue) { 
          const containsNonNumeric = /[^\d]/.test(searchValue);
          setSearch({
              username: containsNonNumeric ? searchValue : '',
              id: containsNonNumeric ? '' : searchValue,
          });
      } else{
              setSearch({
                  username: '',
                  id: '',
              });
          }
      }


      const handleDeleteAdmin = (adminData) => {
        deleteAdmin(adminData.id)
        .then(() => {
        const updatedAdmins = admins.filter((admin) => admin.id !== adminData.id);
        setAdminsData(updatedAdmins); 
        })
        .catch((error) => {
         console.error("Error deleting user:", error);
    });
       };
      
  
  
 return ( <React.Fragment>
            {loader && <Loading />}
            {response && <Permission 
            tab={tab}
            onAddAdmin={onAddAdmin}
            admins={admins}
            response={response}
            handleStatusChange={handleStatusChange}
            onChangePageHandler={onChangePageHandler}
            onSearchHandler = {onSearchHandler}
            loading={loading}
            onDeleteClick={handleDeleteAdmin}
            />}
            </React.Fragment>)

}

export default React.memo(PermissionControlContainer);  