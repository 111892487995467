import React, { useRef, useState } from "react";
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password} from 'primereact/password';
import { classNames } from 'primereact/utils';
import { MainLayout } from "../../components/MainLayout";
import { Toast } from 'primereact/toast';
import Loading from "../../components/shared/Loading";
import { loginUser } from "../../api/methods";
import { TOKEN_KEY, REFRESH_TOKEN, USERNAME, ROLE , PERMISSION} from '../../constants/common';
import { useNavigate } from "react-router-dom";

const Login = (props) => {
    const toast = useRef(null);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.username) {
                errors.username = 'Username is required.';
            }

            if (!data.password) {
                errors.password = 'Password is required.';
            }

            return errors;
        },
        onSubmit: (data) => {
            setLoader(true);

            loginUser(data)
                .then((user) => {
                    sessionStorage.setItem(TOKEN_KEY, user.access_token);
                    sessionStorage.setItem(REFRESH_TOKEN, user.refresh_token);
                    localStorage.setItem(TOKEN_KEY, user.access_token);
                    localStorage.setItem(REFRESH_TOKEN, user.refresh_token);
                    sessionStorage.setItem(USERNAME, data.username);
                    localStorage.setItem(USERNAME, data.username);
                    localStorage.setItem(ROLE, user.role);
                    localStorage.setItem(PERMISSION, user.permission);
                    

                    navigate("/dashboard");
                })
                .catch((error) => {
                    console.error("Login failed:", error);
                })
                .finally(() => {
                    setLoader(false);
                });
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <MainLayout>
            <Toast ref={toast} />
            {loader && <Loading />}
            <div className="login-container">
                <form onSubmit={formik.handleSubmit} className="p-fluid col-6">
                    <div className="field mb-3">
                        <label htmlFor="username" className={classNames({ 'p-error': isFormFieldValid('email') })}>Username *</label>
                        <span className="p-input-icon-right">
                            <InputText id="username" name="username" value={formik.values.username} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('username') })} />
                        </span>
                        {getFormErrorMessage('username')}
                    </div>
                    <div className="field mb-4">
                        <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') })}>Password *</label>
                        <span className="">
                            <Password id="password" name="password" value={formik.values.password} onChange={formik.handleChange} feedback={false}
                                className={classNames({ 'p-invalid': isFormFieldValid('password') })} />
                        </span>
                        {getFormErrorMessage('password')}
                    </div>
                    <Button type="submit" label="Submit" className="mt-4" />
                </form>
            </div>
        </MainLayout>
    );
};

export default React.memo(Login);
