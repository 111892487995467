import React , {useEffect , useState}from "react";  
import DashboardPage from "./DashboardPage";
import Loading from "../../components/shared/Loading";
import { getStatistics , getTotalRegisteredTaxPayersByDate , getTaxpayersReport , getTaxPayersList} from "../../api/methods";

const DashboardContainer = ({tab , ...Props}) =>
{
    const [responseStatistics, setResponseStatistics] = useState(null);
    const [loader, setLoader] = useState(false);
    const [date , setDate ] = useState('');
    const [totalRegistered, setTotalRegistered] = useState(null);
    const [responseData, setResponseData] = useState([]);




    const getDashboardStatisticsHandler = (dateFilter) => {
        if(!dateFilter)
        {
            dateFilter = '';
        }
        setLoader(true);
        getStatistics(dateFilter)
        .then((response) => {
            setLoader(false);
            setResponseStatistics(response)
        })
        .catch((err) => {
            setLoader(false);
                console.log(err);
        })

    };useEffect(() => {
        getDashboardStatisticsHandler();
    }, []);

    const getTotalRegisteredTaxPayersByDateFunction = (searchData) => {
        setDate(searchData);
        setLoader(true);
        getTotalRegisteredTaxPayersByDate(searchData)
        .then((response) => {
            setLoader(false);
            setTotalRegistered(response)
        })
        .catch((err) => {
            setLoader(false);
                console.log(err);
        })

    };


    const onSearchHandler = (searchVal, dateObject , dateFilter) => {
        if(dateFilter)
        {
            // alert("HELLOOOO");
            getTotalRegisteredTaxPayersByDateFunction(dateFilter);
            getDashboardStatisticsHandler(dateFilter);
        }else{
            const containsNonNumeric = /[^\d]/.test(searchVal);
            const searchObject = {
              taxPayerName: containsNonNumeric ? searchVal : '',
              TaxNumber: containsNonNumeric ? '' : searchVal,
              ...dateObject
            };
          
            
            if (!searchObject.taxPayerName && !searchObject.TaxNumber) {
              setResponseData([]);
            } else {
              getTaxPayersListHandler(searchObject);
            }
        };
        }
        const onDownloadReportHandler = () => {
            setLoader(true);
            getTaxpayersReport(date).then((response) => {
                const href = URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'taxpayers.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setLoader(false);
            }).catch((err) => {
                setLoader(false);
            })
        }

        const getTaxPayersListHandler = (searchData) => {
            setLoader(true);
            getTaxPayersList({ page: 1, ...searchData})
                .then((response) => {
                    setLoader(false);
                    setResponseData(response);
                })
                .catch((err) => {
                    setLoader(false);
                    console.log(err);
                })
                
        };
          

    return (
        <React.Fragment>
            {loader && <Loading />}
            {responseStatistics && 
            <DashboardPage
            tab={tab}
            statistics={responseStatistics}
            onSearchHandler={onSearchHandler}
            onDownloadReport={onDownloadReportHandler}
            totalRegistered={totalRegistered}

            />}
            
             
    
        </React.Fragment>)
}
export default React.memo(DashboardContainer);