import React from "react";
import Logform from "./component/Logform/Logform";
import {MainLayout} from '../../components/MainLayout'
import { useTranslation } from "react-i18next";
import './Logs.styles.scss' ;

const Logs = ({logs , tab , onChangePageHandler , onSearchHandler , onDownloadReportHandler}) =>
{
    const { t } = useTranslation();

    return (
    <MainLayout tab={tab} withSidebar={true}>
        <div className="admin_control_section">
        <h2 className={"section_title"}>{t("logs.title")}</h2>

        <Logform logs={logs.logListDto}
                 totalItems={logs.totalItems}
                 pageSize={logs.pageSize}
                 activePage = {logs.currentPage - 1}
                 onChangePage={onChangePageHandler}
                 onSearch={onSearchHandler}
                 onDownloadReport={onDownloadReportHandler}

        />
        
        </div>
        
    </MainLayout>
    )
    
}

export default React.memo(Logs);