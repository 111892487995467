import React from "react";  
import {MainLayout} from '../../components/MainLayout'
import { useTranslation } from "react-i18next";
import {SummaryCard} from '../../components/SummaryCard';
import taxPayers from '../../assets/img/taxPayers.png';
import taxCash from '../../assets/img/taxCash.png';
import taxInvoices from '../../assets/img/taxInvoices.png';
import { SearchTable } from "../../components/SearchTable";
import { ExportReport } from "../../components/ExportReport";
import { TotalRegisteredDisplay } from "../../components/TotalRegisteredDisplay";





const Dashboard = ({tab , statistics , onSearchHandler , onDownloadReport , totalRegistered}) =>
{

    const { t } = useTranslation();
    const overviews = [
        {
            title: t("overview.numberOfTaxpayers"),
            value: statistics.numberOfTaxPayers,
            iconUrl: taxPayers
        },
        {
            title: t("overview.submittedInvoices"),
            value: statistics.numberOfSubmittedInvoices,
            iconUrl: taxInvoices
        },
        {
            title: t("overview.collectedTaxes"),
            value: statistics.totalTaxes + " JOD ",
            iconUrl: taxCash
        },

    ];

    return (
        <MainLayout tab={tab} withSidebar={true}>
            <div className="overviewSection">
            <h2 className={"sectionTitle"}>{t("overview.title")}</h2>
            <div className={"cards"}>
                    {overviews.map((ele, i) => <SummaryCard key={"SummaryCard_" + i} className={"cardItem"} title={ele.title} value={ele.value} icon={ele.iconUrl} />)}
                </div>
             </div>

             <div className="d-flex justify-content-between align-items-center mb-4">
                <SearchTable placeholder={t("taxpayers.searchPlaceholder")}  onSearchHandler={onSearchHandler} isTaxPayerPage={true}/>
                <ExportReport label={"Download as Excel"} onClickHandler={onDownloadReport} />
  
             </div>
             {totalRegistered === 0 ? (
            <div className="no-registered-taxpayers">
                There are no registered tax payers in this month.
            </div>
                ) : totalRegistered > 0 ? (
                <TotalRegisteredDisplay totalRegistered={totalRegistered} />
                ) : null}
            
        </MainLayout>
        )
}
export default React.memo(Dashboard);