import React , {useEffect, useState} from "react";
import Logs from "./Logs";
import { getLogs  , getAdminsLogsReport} from "../../api/methods";
import Loading from "../../components/shared/Loading";

const LogsContainer = ({tab , ...props}) =>
{
const [logs , setLogs] = useState([]) ;
const [loader, setLoader] = useState(false);
const [activePage, setActivePage] = useState(1);
const [search, setSearch] = useState({
    username: '',
    tin: '',
    startDate:'',
    endDate:''
});



const getLogsHandler = () => {
    setLoader(true);
    getLogs({ page: activePage, ...search})
        .then((response) => {
            setLoader(false);
            setLogs(response);
        })
        .catch((err) => {
            setLoader(false);
            console.log(err);
        })
        
};useEffect(() => {
    getLogsHandler();
}, [activePage , search]);

const onChangePageHandler = (pageValue) => {
    setActivePage(pageValue);
}
const onSearchHandler = (searchObject) =>{
    
    if (searchObject.searchValue) { // Check if searchValue is not null or empty
        const containsNonNumeric = /[^\d]/.test(searchObject.searchValue);
        setSearch({
            username: containsNonNumeric ? searchObject.searchValue : '',
            tin: containsNonNumeric ? '' : searchObject.searchValue,
            startDate: searchObject.dateObject.issueDateFrom,
            endDate: searchObject.dateObject.issueDateTo
        });
    } else {
        if(searchObject.dateObject){
        setSearch({
            username: '',
            tin: '',
            startDate: searchObject.dateObject.issueDateFrom,
            endDate: searchObject.dateObject.issueDateTo
        });
        }else{
            setSearch({
                username: '',
                tin: '',
                startDate: '',
                endDate: ''
            });
        }
    }

}
const onDownloadReportHandler = () => {
    setLoader(true);
    getAdminsLogsReport(search).then((response) => {
        const href = URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'taxpayers.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoader(false);
    }).catch((err) => {
        setLoader(false);
    })
}

    return (
    <React.Fragment>
        {loader && <Loading />}
        {
        <Logs 
        logs = {logs} 
        tab={tab}
        onChangePageHandler={onChangePageHandler}
        onSearchHandler={onSearchHandler}
        onDownloadReportHandler={onDownloadReportHandler}
         />
         }

    </React.Fragment>)
}


export default React.memo(LogsContainer);