import React from "react";
import './SummaryCard.styles.scss';


const SummaryCard = ({ title, value, icon, ...props }) => {

    return (
        <div className={"container " + props.className}>
            <div className={"dataContainer"}>
                <p className={"title"}>{title}</p>
                <p className={"value"}>{value}</p>
            </div>
            <img className={"icon"} src={icon} alt="icon" />
        </div>
    )
}

export default React.memo(SummaryCard);