import React from "react";
import './LayoutWithSidebar.styles.scss';
import cn from 'classnames';
import { useTranslation } from "react-i18next";
import{getTabsByPrivilege} from "../../constants/common"; 

const LayoutWithSidebar = ({ children, className, onTabClick, tab: selectedTab }) => {
    const { t } = useTranslation()
    return (
        <div className={cn('sidebar_container', className)}>
            <div className={'sidebar_tabs'}>
                <div className="sidebar_inner">
                    {

                        getTabsByPrivilege().map((tab, i) => (
                            <div onClick={() => onTabClick(tab.value)} key={i} className={cn("sidebar_item", (selectedTab === tab.value) ? 'tab_selected' : '')}>{t(tab.label)}</div>
                        ))
                    }
                </div>
            </div>
            <div className={'sidebar_children'}>
                {children}
            </div>
        </div>
    )
}

export default React.memo(LayoutWithSidebar);