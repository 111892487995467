import React, { useState } from "react";
import './Header.styles.scss';
import profileImg from '../../assets/img/profile.png';
import { Dropdown } from 'primereact/dropdown';
import { useUser } from '../../context/user';
import { REFRESH_TOKEN, TOKEN_KEY } from "../../constants/common";
import { useNavigate } from "react-router-dom";
import { getTabObject } from "../../helpers/common";
import { useTranslation } from "react-i18next";
import { changePassword } from "../../api/methods";
import ChangePasswordForm from "../ChangePasswordForm/ChangePasswordForm";

const Header = ({ tab }) => {
    const { user, dispatchUser } = useUser();
    const { t } = useTranslation()
    const navigate = useNavigate();
    const options = [
        { name: 'Change Password', key: "change" },
        { name: 'Logout', key: "logout" }
        
    ];


    const [showPasswordChangeForm, setShowPasswordChangeForm] = useState(false);

    const logout = () => {
        sessionStorage.removeItem(TOKEN_KEY);
        sessionStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN);

        dispatchUser({
            type: 'SET_USER',
            payload: null,
        });
        navigate("/login");
    };

    const change = async (currentPassword , newPassword) => {
        try {
            const requestData = {
                currentPassword : currentPassword,
                newPassword : newPassword ,
            };

            const response = await changePassword(requestData);

            if (response === "success") {
                setShowPasswordChangeForm(false); 
                alert("Password changed successfully!");
            } else {
                 alert("ERROR");
            }
        } catch (error) {
            console.error('Password change failed:', error);
        }
    }

    const onCancel = () => {
        setShowPasswordChangeForm(false); 
    }

    const onChange = (e) => {
        const key = e.value;
        switch (key) {
            case "logout":
                logout();
                break;
            case "change":
                setShowPasswordChangeForm(true);
                break;
            default:
                console.log("Hello");
        }
    }

    return (
        <div className={"header-container"}>
            <h1 className={'title mx-3'}>{t(getTabObject(tab)?.label)}</h1>
            {user && <div className={"profileUser"}>
                <h6 className="my-auto mx-2">{user.userName}</h6>
                <Dropdown onChange={onChange} options={options} optionLabel="name" optionValue="key" />
                <img className={"profileImg"} src={profileImg} alt="profile" />
            </div>}
            {showPasswordChangeForm && <div className="overlay"></div>}
            {showPasswordChangeForm && <ChangePasswordForm onChangePassword={change} onCancel={onCancel} />}
        </div>
    )
}

export default React.memo(Header);
